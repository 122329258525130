.ant-collapse-header{
    background-color: none !important;
    
}

.ant-collapse-item{
    border:1px solid #EAEAEA !important;
    border-radius: 8px;
    
}
.ant-collapse-expand-icon{
    color:#0873B9;
}
 
.demand-title .ant-modal-title {
	background: var(--blueDark);
	color: white !important;
	padding: 20px 50px;
	font-size: 25px !important;
	font-weight: 700 !important;
	letter-spacing: -0.462px;
    font-family: 'DM Sans';
	height:127px;
	display: flex;
	align-items: center;

}

.ant-modal-close-x{ 
	font-size: 22px !important;
	display: flex;
	align-items: center;
	
}
.close-con-demand .ant-modal-close{
	top: 7% !important;
	right:6% !important;
}
 
.mod .ant-modal-footer{
    display: none;
}
.mod-height-demand .ant-modal-content { 
    height: auto; 
}

.mod-footer .ant-modal-footer{
	display: none !important;
}

.demand-body .ant-modal-body {
    padding: 20px 50px !important;
}
