.candidates-table .ant-checkbox-inner {
    width: 16px !important;
    height: 16px !important;
    margin-top: 4px !important;
    border-radius: 4px !important;
    border: 1px solid var(--nea-blue, #147cbc) !important;
}
.stage{  
    padding: 4px 12px; 
}
.candidate-select .ant-select-selector{
	background-color: var(--blueDark) !important;
	color:white !important; 
	border-radius: 4px;
	border: 1px solid var(--blueDark) !important;
}
.candidate-arrow .ant-select-arrow{
	color:white !important; 
}
.candidate-select-placeholder .ant-select-selection-placeholder{
	color:white !important; 
}
.candidate-select-placeholder .ant-select-selection-item{
	font-size: 16px;
	font-family: 'DM Sans' sans-serif !important;
}

.candidate-select-activity .ant-select-selector{
	background-color: #fff !important; 
	border-radius: 50px;
	box-shadow: 0px 0px 22px 0px #1A1C2812 !important; 
	border: none !important; 
	font-family: 'DM Sans' sans-serif !important; 
}
.candidate-arrow-activity .ant-select-arrow{
	color:#212121 !important; 
}
.candidate-select-placeholder-activity .ant-select-selection-placeholder{
	color:#626364 !important; 
	font-family: 'DM Sans' sans-serif !important; 
}
.candidate-select-placeholder-activity .ant-select-selection-item{
	font-size: 16px;
	font-family: 'DM Sans' sans-serif !important; 
}
 


