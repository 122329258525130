@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');

:root {
	/* --mainBg: #F8F8F8; */
	--mainBg: #FFF;
	--darkBlue: #147cbc;
	--blue: #0873b9;
	--lightBlue: #edf8ff;
	--softBlue: #e8f4ff;
	--blackest: #141414;
	--black: #000;
	--black1: #333;
	--black2: #222;
	--black3: rgba(20, 20, 20, 0.7);
	--gray: #5a5a5a;
	--darkPurple: #7d5ae2;
	--lightPurple: #e3e1ff;
	--blueDark:#1B47B4; 
	background-color: var(--mainBg) !important;
	font-family: 'DM Sans', sans-serif !important;
}

html {
	background-color: var(--mainBg) !important;
	overflow-y: hidden;
	font-family: 'DM Sans', sans-serif !important;
}

body {
	background-color: var(--mainBg) !important;
	font-family: 'DM Sans', sans-serif !important;
}

textarea {
	border-radius: 8px !important;
	border: 1px solid #000 !important;
}

label.ant-form-item-required {
	color: var(--text-black, #222);
	font-family: 'DM Sans', sans-serif !important;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

label {
	font-family: 'DM Sans', sans-serif !important;
	font-style: normal !important;
	font-weight: 500 !important;
	color: var(--Text-Primary, #212121) !important;
	font-size: 1rem !important;
	line-height: 1.5rem !important;
}

[hidden] {
	display: none !important;
}

.ant-form-item-required::before {
	content: '' !important;
}

.ant-form-item-required::after{
	content: '*' !important;
	visibility: visible !important;
}

.ant-drawer-header-title {
	flex-direction: row-reverse;
}

.ant-modal-root{
	height: 100vh !important;
}

.ant-modal-wrap{
	min-height: 500px !important;
	/* height: 100% !important; */
	/* overflow-y: hidden !important; */
	/* position: relative !important; */
}

.ant-modal-content {
	padding: 0 !important;
	z-index: 1000;
	max-height: 100%;
	height: auto;
}

.ant-modal-body {
	padding: 20px 47px !important;
	background: inherit;
	border-radius: 0.875rem !important;
	min-width: 800px !important;
	width: 100% !important;
	max-height: 100% !important;
	min-height: 422px !important;
}

/* .ant-modal-body {
	min-width: 37.875rem !important;
	max-height: 100% !important;
	min-height: 422px !important;
	overflow-y: auto !important;
	padding: 20px 47px !important;
	background: inherit;
	border-radius: 0.875rem !important;
} */

.ant-modal{
	display: flex !important;
	justify-content: center !important;
}
 
  
.modal-btn{
	/* top: -850px !important; */
}

.activity .ant-modal-body {
	padding: 20px 30px !important;
}

.users .ant-modal-body {
	padding: 0px 30px !important;
	min-width: 800px !important;
	width: 100% !important;
}

.ant-modal-title {
	background: var(--blueDark);
	color: white !important;
	padding: 27px 47px;
	font-size: 20px !important;
	font-weight: 500 !important;
	letter-spacing: -0.462px;
    font-family: 'DM Sans';
	border-radius: 5px 5px 0px 0px;
}

 

.ant-modal-close-x{ 
	font-size: 20px !important;
	display: flex;
	align-items: center;
	padding-right: 27px;
	color: #fff;
}

 

.ant-modal-footer {
	padding: 20px !important;
}

/* .ant-steps {
	display: none !important;
} */

.ant-input-number-input {
	height: 42px !important;
}

.ant-input-number-handler-wrap {
	display: none !important;
}

.ant-upload-icon>.anticon,
.ant-upload-list-item-name {
	color: #0a0a0a !important;
}

.drawer-icon .anticon {
	color: #fff !important;
	margin-right: 28px;
	font-size: 30px;
}

.ant-tabs-tab {
	background-color: inherit !important;
	color: var(--black1) !important;
	font-family: 'DM Sans' !important;
	font-size: 20px !important;
	font-style: normal;
	font-weight: 500 !important;
}

.ant-tabs-tab-active {
	background-color: var(--bs-dark) !important;
	color: var(--bs-orange) !important;
}

.ant-tabs-tab-active svg {
	fill: var(--blueDark) !important;
}

.ant-tabs-tab-active path {
	fill: var(--blueDark) !important;
}

.ant-tabs-tab svg {
	fill: var(--black1);
}

.ant-tabs-nav-list {
	width: 100%;
	display: flex;
	justify-content: space-between;
}


.ant-tabs-nav{
	margin: 0px !important;
}

 

.users-tab .ant-tabs-nav-list {
	width: 30%;
	display: flex;
	justify-content: space-between;
}

.returns-tab .ant-tabs-nav-list {
	width: 70%;
	display: flex;
	justify-content: space-between;
}

.employees-tab .ant-tabs-nav-list {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-left: 139px;
	padding-right: 139px;
}

.ant-tabs-nav-wrap {
	padding: 40px 40px !important;
	background: inherit !important;
}
.jobs-tab .ant-tabs-nav-list {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.jobs-tab-inside .ant-tabs-nav-list {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
 
.jobs-tab-wrap .ant-tabs-nav-wrap {
	padding-top: 1rem !important;
	padding-bottom: 2.5rem !important;
	padding-left:70px !important;
	padding-right:70px !important;
	background-color: white !important; 
	background-color: white !important;
}

.returns-tab-wrap .ant-tabs-nav-wrap {
	padding: 40px 0px !important;
	background-color: inherit !important;
}

.job-bar .ant-tabs-ink-bar {
	width: 9.0625rem !important;
	height: 5px !important;
	border-radius: 8px !important;
	background-color: var(--blueDark) !important;
	display: flex;
	justify-content: space-between ;
	align-items: center ;
}

.recommedation-tab-wrap .ant-tabs-nav-wrap {
	padding: 22px 71px !important;
	justify-content: center;
}

.applicants-tab .ant-tabs-nav-list {
	width: 50%;
	display: flex;
	justify-content: space-between;
}

.ant-tabs-tab-active span {
	color: var(--blueDark) !important;
	/* padding-left: 5px !important;
	padding-right: 5px !important; */
}

.employee-bar .ant-tabs-ink-bar {
	width: 115px !important;
	height: 5px !important;
	border-radius: 8px !important;
	background-color: var(--blueDark) !important;
}
.employee-tab .ant-tabs-nav-list {
	width: 20%;
	display: flex;
	justify-content: space-between;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
	color:var(--blueDark)
}


.custom-tab .ant-tabs-tab-btn {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.custom-tab .ant-tabs-ink-bar-animated {
	display: none !important; 
}

.custom-tab .ant-tabs-nav-list {
	display: flex;
	justify-content: start !important;
	margin-bottom: 20px;
	border-bottom: 1px solid #ececec;
	padding-bottom: 20px;
}

.custom-tab .ant-tabs-tab {
	margin-right: 30px;
}

.custom-tab .ant-tabs-nav-wrap {
	padding: 0px !important;
}

.ant-picker-range {
	width: 100% !important;
}

.editable-cell {
	position: relative;
}

.editable-cell-value-wrap {
	padding: 5px 12px;
	cursor: pointer;
}

.editable-jobs .editable-cell-value-wrap {
	height: 44px !important;
	cursor: text !important;
}

.editable-row:hover .editable-cell-value-wrap {
	padding: 4px 11px;
	/* border: 1px solid #d9d9d9; */
	/* border-radius: 2px; */
	border: none !important;
	background: #E7F1FF !important; 
}
 
 .ant-select .ant-select-arrow {
	color: #101828 !important;
 }
 

.ant-radio-inner {
	border-color: #147cbc !important;
}

.ant-spin-dot-item {
	background-color: white !important;
}

.spin-modal .ant-spin-dot-item {
	background-color: var(--blueDark) !important;
}

/* .ant-select-focused .ant-select-selector {
	border: none !important; 
	box-shadow: none !important;  
  }
   */

.ant-table-thead th {
	background-color: var(--lightBlue) !important;
	font-weight: 700 !important; 
}
 
.ant-drawer-content-wrapper {
	top: 100px !important;
}

.ant-drawer-header {
	padding: 34px 24px !important;
	background-color: var(--blueDark);
}

.job-view .ant-drawer-header {
	padding: 20px 24px !important;
	background-color: var(--blueDark);
}

.job-view-body .ant-drawer-body {
	padding: 0px 24px !important;
}

.ant-drawer-title {
	color: #fff !important;
	font-family: 'DM Sans';
	font-size: 34px !important;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
	margin: 0px 30px !important;
}

label.ant-form-item-required {
	color: var(--text-black, #222);
	font-family: 'DM Sans', sans-serif !important;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

label {
	color: var(--text-black, #222);
	font-family: 'DM Sans', sans-serif !important;
	font-size: 18px !important;
	font-style: normal;
	font-weight: 500;
}

.notification-dropdown>.ant-dropdown-menu {
	min-height: 500px !important;
	height: 80vh !important;
	overflow-y: scroll !important;
	padding: 0px !important;
}

.notification-dropdown .ant-dropdown-menu-item {
	padding: 0px !important;
	margin: 5px !important;
}

.ant-dropdown-menu {
	padding: 20px !important;
}

.ant-dropdown-menu-item {
	margin: 20px 10px !important;
}

.profile-dropdown > .ant-dropdown-menu{
	padding: 0 !important;
}

.profile-dropdown .ant-dropdown-menu-item {
	margin: 0 !important;
}

.profile-dropdown .ant-dropdown-menu-item:hover {
	background-color: transparent !important;
}

.ant-upload-drag {
	border-radius: 1px !important;
	border: 1px dashed var(--nea-blue, #147cbc) !important;
	background: var(--White, #fff) !important;
	height: 64px !important;
}

.attachment .ant-upload-drag {
	border-radius: 1px !important;
	background: #fff !important;
	height: 205px !important;
	border: none !important;
}

.ant-upload-text {
	color: var(--nea-blue, #147cbc) !important;
	font-size: 18px !important;
	font-style: normal;
	font-weight: 500 !important;
	line-height: normal;
}

.ant-table {
	font-family: 'DM Sans' !important;
	font-size: 1rem !important;
	font-weight: 400 !important;
}

.job-filter input::placeholder {
	color: #9b9b9e !important;
	font-size: 16px !important;
	font-style: normal;
	font-weight: 700 !important;
	line-height: 24.5px;
}

.ck-toolbar__items {
	background-color: red !important;
}

.ant-checkbox-inner {
	width: 20px !important;
	height: 20px !important;
	/* margin-top: -4px !important; */
	border-radius: 4px !important;
	border: 1px solid var(--nea-blue, #147cbc) !important;
}

.ant-checkbox .ant-checkbox-inner::after {
	width: 8px !important;
	height: 12px !important;
}

.recommedation-selector .ant-select-selector {
	border-radius: 72px !important;
	color: var(--blueDark) !important;
	border: 1px solid var(--blueDark) !important;
	font-size: 18px !important;
	font-weight: 500 !important;
	font-family: 'DM Sans' sans-serif !important;
}

.recommedation-selection .ant-select-selection-item {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	color: var(--blueDark) !important;
}

.recommedation-arrow .ant-select-arrow {
	color: var(--blueDark) !important;
}

.recommedation-bar .ant-tabs-ink-bar {
	width: 639px !important;
	height: 5px !important;
	border-radius: 8px !important;
}

.returns-bar .ant-tabs-ink-bar {
	width: 171px !important;
	height: 5px !important;
	border-radius: 8px !important;
	background-color: var(--blueDark) !important;
}
 
 

.employees-bar .ant-tabs-ink-bar {
	width: 438px !important;
	height: 5px !important;
	border-radius: 8px !important;
}

.ant-picker-ok button {
	background-color: var(--blueDark) !important;
	color: #fff !important;
}

.activity-select-tag.ant-select-multiple .ant-select-selection-item {
	background: #dbebf7;
	color: var(--blueDark);
	border-radius: 36px;
	height: 30px;
	display: flex;
	align-items: center;
}

.activity-select-icon .anticon {
	color: var(--blueDark);
}

.ant-select-selection-search-input {
	--tw-ring-color: none !important;
	--tw-ring-offset-shadow: none !important;
	--tw-ring-shadow: none !important;
	box-shadow: none !important;
	border-color: none !important;
	background: inherit !important;
}

.ant-select-selector {
	border: 1px solid #000 !important;
	box-shadow: none !important;
}

.con-title {
	color: rgba(0, 0, 0, 0.7);
	font-size: 23px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
}

.ant-breadcrumb-link {
	color: #64748b;
	font-family: 'DM Sans' sans-serif !important;
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}

.ant-picker-suffix {
	color: var(--black) !important;
}

input::placeholder {
	color: var(--black) !important;
}

.job-vacancy-modal .ant-form-item-label>label {
	font-size: 18px !important;
	font-weight: 500;
	color: var(--text-black, #222);
}

.ant-tag {
	margin-right: 3px;
	display: flex;
	padding: 5px 15px;
}

.inbox-select .ant-tag {
	background-color: var(--blueDark);
}

.ant-menu {
	border-inline-end: none !important;
}

.email-table .ant-table-thead {
	display: none !important;
}

.ant-table-cell {
	border-bottom: none !important;
}

.react-tel-input .form-control {
	height: 100% !important;
	width: 100% !important;
	border: none !important;
	outline: none !important;
	padding-left: 75px !important;
}

.flag-dropdown {
	padding: 10px !important;}

.ant-input-affix-wrapper{
	border-radius: 50px;
	width:423px;
	height: 50px; 
	box-shadow: 0 0 22px 0 rgba(26, 28, 40, 0.07) !important;
	border: none !important;
	font-size: 18px; 
	background: #fff !important;
}

.ant-form-item-explain-error{
	color: var(--blueDark) !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	/* font-family: 'DM Sans' !important;  */
}
.ant-form-item-extra{
	font-size: 14px !important;
	font-weight: 400 !important;
	color:#212121 !important;
	/* font-family: 'DM Sans' !important;  */
}

.ant-tabs-tab-btn {
	font-size: 21px;
	font-weight: 500; 
}
 
.ant-collapse-borderless >.ant-collapse-item {
	border: none !important;
}


.ant-collapse-item >.ant-collapse-header{
	display: flex;
	justify-content: center !important;
	align-items: center !important;
	height:81px;
	border-radius:7px, 7px, 0px, 0px; 
	background-color: #F9FDFF !important; 
}

.activity-tab-wrap .ant-tabs-nav-wrap {
	padding: 40px 0px !important;
	background-color: inherit !important;
}
.activity-tab .ant-tabs-nav-list {
	width: 30%;
	display: flex;
	justify-content: space-between;
}
.activity-bar .ant-tabs-ink-bar {
	width: 115px !important;
	height: 5px !important;
	border-radius: 8px !important;
	background-color: var(--blueDark) !important;
	display: flex;
	justify-content: space-between ;
	align-items: center ;
}

.ant-form-item .ant-form-item-extra{
	color:var(--blueDark) !important;
	font-weight: 400;
	font-size: 14px !important;
}

.ant-badge-count {
	font-weight: 500 !important;
	background: var(--darkBlue) !important;
	box-shadow: none !important;
}

.ant-badge-dot{
	top: 0.45rem !important;
    left: 1.2rem !important;
}

.save-btn{
	display: flex;
	height: 3.125rem;
	padding: 0.75rem 3rem;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
	border-radius: 1.75rem;
	background: var(--Button-primary, #1B47B4);
	color: var(--Text-Inverse-Primary, #FFF);
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;  
}
.cancel-btn{
	color: var(--Button-primary, #1B47B4);
	text-align: right; 
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;  
	height: 3.125rem;
	padding: 0.75rem 3rem;
	border-radius: 1.75rem;
}

.confirm-h{
	color: var(--Text, #333); 
	font-family: "DM Sans";
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
	line-height: 120%; 
}
.confirm-p{
	color: #333;  
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;  
}