@font-face {
	font-family: 'Satoshi';
	src: url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2") format('woff2'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff") format('woff'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf") format('truetype'),
}

.ql-container {
	font-size: 18px !important;
}

.ql-size-huge {
	@apply text-4xl;
}

.ql-size-large {
	@apply text-2xl;
}

.ql-size-small {
	font-size: 12px;
}

.ql-align-center {
	text-align: center;
}

.ql-align-right {
	text-align: right;
}

.ql-align-justify {
	text-align: justify;
}

.ql-indent-1 {
	margin-left: 30px;
}

.ql-indent-2 {
	margin-left: 60px;
}

.ql-indent-3 {
	margin-left: 90px;
}

.ql-indent-4 {
	margin-left: 120px;
}

.ql-indent-5 {
	margin-left: 150px;
}

.table-head {
	color: #000;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.table-sub-head {
	color: #000;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.nav-items {
	color: #282927;
	font-family: 'Outfit', sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24.5px;
	/* 122.5% */
}

.profile-name {
	color: #000;
	font-family: 'DM Sans', sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.ant-upload-drag {
	border-radius: 8px !important;
	border: 1px solid var(--nea-blue, #282927) !important;
	background: var(--White, #fff) !important;
	height: 44px !important;
}

.blueBtn .ant-upload-drag {
	border-radius: 56px !important;
	border: none !important;
	background: var(--blueDark) !important;
	height: 44px !important;
}

.profile-email {
	color: var(--Inactive-menu-item, #7792b7);
	text-align: center;
	font-family: 'DM Sans', sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.profile-no {
	color: var(--Inactive-menu-item, #7792b7);
	text-align: center;
	font-family: 'DM Sans', sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.profile-link {
	color: var(--nea-blue, #147cbc);
	text-align: center;
	font-family: 'DM Sans', sans-serif;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-decoration-line: underline;
}

.pws-radio {
	color: var(--nea-blue, #147cbc);
	text-align: center;
	font-family: DM Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	/* 21.6px */
}

.profile-status {
	background: rgba(87, 168, 99, 0.11) !important;
	color: #34B749;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 28.5px */
}

.profile-unstatus {
	background: rgba(168, 106, 87, 0.21);
	color: #d20000;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 28.5px */
}

.profile-status-rejected {
	background: red !important;
	color: white;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 28.5px */
}
.profile-status-pending {
	background: #FF9D00 !important;
	color: white;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 28.5px */
}

.slanted-background-2 {
	background-blend-mode: normal;
	/* background: url('./assets/home_man.png'),
		linear-gradient(85deg, #f9fdff 60%, #147cbc calc(40% + 1px)); */
	background-size: 50% 70%, 95% 100%;
	background-repeat: no-repeat;
	background-position: right;
	background-position-y: 50%;
	object-fit: contain;
	width: 100%;
	position: relative;
	z-index: 1000;
}

.kenya-text {
	background: linear-gradient(to bottom, #000 35%, #c8001b 60%, #006f30 30%),
		linear-gradient(#000 35%, #c8001b 60%, #006f30 30%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

/* 
.menu-item {
	color: var(--Inactive-menu-item, #7792B7);
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
} */

.active {
	color: var(--nea-blue, #147cbc) !important;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
}

.card {
	width: 100%;
	border-radius: 14px 14px 0px 0px;
	text-align: center;
	user-select: none;
}

.cover-photo {
	position: relative;
	background: var(--blueDark);
	background-size: cover;
	height: 92px;
	border-radius: 14px 14px 0px 0px;
}

.profile {
	position: absolute;
	width: 100px;
	height: 100px;
	bottom: -40px;
	left: 30%;
	border-radius: 50%;
	padding: 5px;
	z-index: 10000;
	background-color: #fff;
}

.profile-name {
	font-size: 20px;
	color: #000;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.card-main {
	width: 100%;
	border-radius: 16px 16px 0px 0px;
	user-select: none;
}

.cover-photo-main {
	position: relative;
	border-radius: 16px 16px 0px 0px;
	background: rgba(20, 124, 188, 0.1);
	background-size: cover;
	height: 160px;
}

.profile-main {
	/* position: absolute; */
	width: 139px;
	height: 139px;
	bottom: -45%;
	left: 15%;
	border-radius: 50%;
	padding: 10px;
	/* background: #fff; */
}

.prof-name {
	color: #141414;
	font-size: 46px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 84px */
}

.prof-desc {
	color: rgba(0, 0, 0, 0.7);
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 30px */
}

.prof-edit {
	color: #0873b9;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-decoration-line: underline;
}

.prof-card-name {
	color: #000;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 24.5px;
	/* 76.563% */
}

.prof-reg {
	color: var(--Text-Black, #333);
	font-size: 19px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.prof-job {
	color: var(--Text-Black, #333);
	font-size: 21px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.prof-loc {
	color: #7d7d7d;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.prof-card-head {
	color: #333;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.prof-card-text {
	color: var(--Text-Black, #333);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.prof-card-badge {
	color: var(--NEA-Purple, #262168);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24.5px;
	/* 122.5% */
}

.prof-card-tips {
	color: #333;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.prof-cert-text {
	color: var(--nea-blue, #147cbc);
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-decoration-line: underline;
}

.prof-info-head {
	color: #333;
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.prof-with-dsablt {
	color: #000;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.profile-basic-info {
	color: var(--text-black, #222);
	font-size: 17px;
	font-style: normal;
	line-height: 150%;
	/* 31.5px */
	letter-spacing: -0.231px;
}

.edit-prof-desc {
	color: var(--text-black, #222);
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	/* 26.4px */
}

.edit-step-head {
	color: #333;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.edit-step-desc {
	color: var(--text-black, #222);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	/* 26.4px */
}

.label-info {
	color: var(--text-black, #222);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	/* 26.4px */
}

.check-text {
	color: var(--text-black, #222);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	/* 26.4px */
}

.dash-welcome {
	color: #333;
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: 140%;
	/* 50.4px */
}

.dash-welcome-text {
	color: #333;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 36px */
}

.dash-welcome-verify-text {
	color: var(--nea-blue, #147cbc);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	/* 33.6px */
}sa

.cv-desc {
	color: rgba(0, 0, 0, 0.7);
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 36px */
}

.job-count {
	color: #141414;
	font-size: 29px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 48px */
}

.job-apply-text {
	color: var(--nea-blue, #147cbc);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 30px */
}

.job-filter-text {
	color: #141414;
	font-size: 21px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 36px */
}

.radio-head {
	color: #141414;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 31.5px */
}

.job-filter-border {
	border-radius: 3px;
	border: 1px solid rgba(20, 20, 20, 0.05);
	background: var(--White, #fff);
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
}

.go-back {
	color: var(--text-black, #222);
	font-size: 21px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	/* 26.4px */
}

.job-info-header {
	color: #141414;
	font-size: 21px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 31.5px */
}

.job-info-header-cv {
	color: #141414;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 36px */
}

.job-info-text {
	color: rgba(20, 20, 20, 0.7);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 31.5px */
}

.job-info-text-head {
	color: rgba(20, 20, 20, 0.7);
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 31.5px */
}

.dot-list {
	list-style-type: disc;
	margin-left: 7.5%;
}

.app-status-info {
	color: #141414;
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 48px */
}

.app-status-text {
	color: #333;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 27px */
}

.app-intv-gray {
	color: #888;
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 36px */
}

.app-intv-black {
	color: #141414;
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 36px */
}

.int-reqs {
	color: #141414;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 36px */
}

.num-list {
	list-style: none;
	counter-reset: item;
}

.num-list li {
	counter-increment: item;
}

.num-list li::before {
	margin-right: 10px;
	content: counter(item) '.';
}

.int-no {
	color: var(--nea-blue, #147cbc);
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: 24.5px;
	/* 122.5% */
}

.offer-text {
	color: #006f30;
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 36px */
}

.edit-header {
	color: var(--text-black, #222);
	font-size: 29px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	/* 38.4px */
}

.edit-visible {
	color: var(--Text-Black, #333);
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.ul-disk {
	list-style-type: disc;
	padding-left: 25px;
}

.cv-scroll::-webkit-scrollbar {
	width: 20px;
}

.cv-scroll::-webkit-scrollbar-track {
	background-color: transparent;
}

.cv-scroll::-webkit-scrollbar-thumb {
	background-color: #147cbc;
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

.cv-scroll::-webkit-scrollbar-thumb:hover {
	background-color: #0873b9;
}

.triangleStyle {
	content: "''";
	position: 'absolute';
	top: 10;
	left: '10%';
	width: '50px';
	height: '50px';
	background-color: #147cbc;
	border-style: solid;
	border-width: 28.5px 20px 28.5px 0;
	border-color: transparent transparent transparent #004080;
	border-top-right-radius: 20px solid #eee;
	border-bottom-right-radius: 20px solid #eee;
	color: #edf8ff;
	font-weight: bold;
	/* border-right: 20px solid #147CBC; */
}

.triangleStyle2 {
	content: "''";
	position: 'absolute';
	top: 10;
	left: '10%';
	width: '50px';
	height: '50px';
	background-color: #edf8ff;
	border-style: solid;
	border-width: 28.5px 20px 28.5px 0;
	border-color: transparent transparent transparent #004080;
	border-top-right-radius: 20px solid #eee;
	border-bottom-right-radius: 20px solid #eee;
	border-left: 86px solid #147cbc;
	color: var(--blueDark);
	font-weight: bold;
}

.triangleStyle3 {
	content: "''";
	position: 'absolute';
	top: 10;
	left: '10%';
	width: '50px';
	height: '50px';
	background-color: #edf8ff;
	border-style: solid;
	border-width: 28.5px 20px 28.5px 0;
	border-color: transparent transparent transparent #004080;
	border-top-right-radius: 20px solid #eee;
	border-bottom-right-radius: 20px solid #eee;
	color: var(--blueDark);
	font-weight: bold;
}

.triangleStyle4 {
	content: "''";
	position: 'absolute';
	top: 10;
	left: '10%';
	width: '50px';
	height: '50px';
	background-color: #147cbc;
	border-style: solid;
	border-width: 28.5px 20px 28.5px 0;
	border-color: transparent transparent transparent #004080;
	border-top-right-radius: 86px solid #fff;
	border-bottom-right-radius: 86px solid #fff;
	color: #edf8ff;
	font-weight: bold;
	/* clip-path: polygon(0 0, 50% 50%, 0 100%); */
	/* border-right: 86px solid #fff; */
}

.triangleStyle5 {
	content: "''";
	position: 'absolute';
	top: 10;
	left: '10%';
	width: '50px';
	height: '50px';
	background-color: #fff;
	border-style: solid;
	border-width: 28.5px 20px 28.5px 0;
	border-color: transparent transparent transparent #004080;
	border-top-right-radius: 86px solid #fff;
	border-bottom-right-radius: 86px solid #fff;
	color: #edf8ff;
	font-weight: bold;
	/* clip-path: polygon(0 0, 50% 50%, 0 100%); */
	border-left: 86px solid #147cbc;
}

.appl-head {
	color: var(--Text-Black, #141414);
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
	/* 33.6px */
}

.bg-line {
	background: linear-gradient(180deg,
			rgba(0, 0, 0, 0) calc(50% - 1px),
			#000 calc(50%),
			rgba(0, 0, 0, 0) calc(50% + 1px));
}

.app-proc-text {
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 31.5px */
}

.billing-active {
	cursor: pointer;
	border-radius: 5px;
	border: 3px solid var(--nea-blue, #147cbc);
	border-bottom: 7px solid #147cbc;
	background: var(--White, #fff);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 142px;
	height: 121px;
}

.billing-active-icon {
	padding: 10px;
	width: 35px;
	height: 35px;
	background: var(--nea-blue, #147cbc);
	box-shadow: 0px 1px 2px 0px rgba(105, 81, 255, 0.05);
	color: var(--White, #fff);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 24px */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.billing-active-text {
	color: var(--nea-blue, #147cbc);
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 31.5px */
	margin-top: 7px;
}

.billing {
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid var(--nea-blue, #147cbc);
	background: var(--White, #fff);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 142px;
	height: 121px;
}

.billing-icon {
	padding: 10px;
	width: 35px;
	height: 35px;
	background: var(--nea-blue, #fff);
	border: 1px solid var(--nea-blue, #147cbc);
	box-shadow: 0px 1px 2px 0px rgba(105, 81, 255, 0.05);
	color: var(--blueDark, #147cbc);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 24px */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.billing-text {
	color: var(--nea-blue, #147cbc);
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 31.5px */
	margin-top: 7px;
}

.license-header {
	color: var(--text-black, #222);
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	/* 33.6px */
}

.billing-done {
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid var(--nea-blue, #147cbc);
	background: #edf8ff !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 142px;
	height: 121px;
}

.license-subtext {
	color: var(--Text, #333);
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 31.5px */
}

.license-pay-text {
	color: #000;
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 31.5px */
}

.license-agent-text {
	color: #000;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.license-mpesa-choice {
	color: var(--Text, #333);
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 31.5px */
}

.license-pay-submit-text {
	color: var(--nea-blue, #147cbc);
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	/* 25.2px */
}

.license-pay-submit-text-v2 {
	color: var(--nea-blue, #141414);
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	/* 25.2px */
}

.license-upload-text {
	color: var(--Text, #333);
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 31.5px */
}

.no-emp-text {
	color: var(--Text-Black, #141414);
	text-align: center;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 33px */
}

.no-emp-desc {
	color: #9d9797;
	text-align: center;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 33px */
}

.tb-title {
	color: #333333;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 32.4px;
	font-family: 'Satoshi'; 
	/* 36px */
}

.search-btn {
	display: inline-flex;
	height: 59px;
	padding: 19px 18px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	flex-shrink: 0;
	border-radius: 4px;
	border: 1px solid #f6f6f6;
	background: var(--nea-primary-50, #edf8ff);
	box-shadow: 0px 1px 2px 0px rgba(105, 81, 255, 0.05);
	color: var(--nea-primary-500, var(--nea-blue, #147cbc));
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	/* 120% */
}

.accordion-sub-title {
	color: var(--blueDark);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 30px */
}

.no-email-black {
	color: var(--Text-Black, #141414);
	text-align: center;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 33px */
}

.no-email-gray {
	color: #9d9797;
	text-align: center;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 33px */
}

.unread {
	font-size: 20px;
	font-weight: 700;
	color: #147cbc;
	line-height: 140%;
}

.unread-img {
	@apply w-12 h-12 object-cover rounded-full border-4 border-blueDark;
}

.unread-body {
	@apply text-[17px] w-[300px] truncate ml-1 text-blueDark font-semibold;
}

.unread-icon {
	@apply flex justify-center items-center p-1 text-[32px] text-blueDark object-cover rounded-full border-4 border-blueDark;
}

.unread-svg path {
	fill: #147cbc;
	color: #147cbc;
	font-weight: 900;
}

.unread-svg circle {
	fill: #147cbc;
	color: #147cbc;
	font-weight: 900;
}

.read {
	font-size: 20px;
	font-weight: 500;
	color: #141414;
	line-height: 140%;
}

.read-img {
	@apply w-12 h-12 object-cover rounded-full border-2 border-blackest;
}

.read-body {
	@apply text-[17px] w-[300px] truncate ml-1 text-blackest font-normal;
}

.read-icon {
	@apply flex justify-center items-center p-1 text-[32px] text-blackest object-cover rounded-full border-2 border-blackest;
}

.read-svg path {
	fill: #141414;
	color: #141414;
	font-weight: 400;
}

.read-svg circle {
	fill: #141414;
	color: #141414;
	font-weight: 400;
}

.inb-view-icon {
	cursor: pointer;
	padding: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	@apply rounded-full;
}

.inb-view-icon:hover {
	background-color: #147cbc;
}

.inb-view-icon:hover path {
	fill: white;
}

.adm-doc-name {
	color: #000;
	font-family: 'DM Sans';
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	/* 114.286% */
}

.dereg-text {
	color: var(--text-black, #222);
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	/* 25.2px */
}



.loading {
	position: fixed;
	z-index: 999;
	height: 2em;
	width: 2em;
	overflow: show;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
  }
  
  /* Transparent Overlay */
  .loading:before {
	content: '';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	  background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));
  
	background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
  }
  
  /* :not(:required) hides these rules from IE9 and below */
  .loading:not(:required) {
	/* hide "loading..." text */
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
  }
  
  .loading:not(:required):after {
	content: '';
	display: block;
	font-size: 10px;
	width: 1em;
	height: 1em;
	margin-top: -0.5em;
	-webkit-animation: spinner 150ms infinite linear;
	-moz-animation: spinner 150ms infinite linear;
	-ms-animation: spinner 150ms infinite linear;
	-o-animation: spinner 150ms infinite linear;
	animation: spinner 150ms infinite linear;
	border-radius: 0.5em;
	-webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
  }
  
  /* Animation */
  
  @-webkit-keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  @-moz-keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  @-o-keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  @keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }

.dash-title{
	font-family: 'Satoshi'; 
}
.empty-desc{
	color: var(--Text-secondary, #616161); 
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;  
}


