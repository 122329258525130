/* .ant-collapse-header{
    background-color: #F9FDFF !important; 
} */


.ant-collapse-item{
    border:1px solid #EAEAEA !important;
    border-radius: 8px;
    
}
.ant-collapse-expand-icon{
    color:#0873B9;
}
 

.activity-header .ant-modal-title {
	background: var(--blueDark);
	color: white !important;
	padding: 27px 47px;
	font-size: 20px !important;
	font-weight: 500 !important;
	letter-spacing: -0.462px;
    font-family: 'DM Sans';
}


.activity-close .ant-modal-close-x{ 
	font-size: 22px !important;
	display: flex;
	align-items: center;
	margin-right: 30px;
}

.ant-modal-close{
	top: 27px !important;
}
 
.mod .ant-modal-footer{
    display: none;
}
.mod-height .ant-modal-content { 
    height: 100%;  
}
.mod-height-applicants .ant-modal-content { 
    height: 800px;  
}

.mod-footer .ant-modal-footer{
	display: none !important;
}
